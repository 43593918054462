import React, { useContext } from "react"

import { AuthContext } from "../../store/auth"
import {
  SUPERVISOR_BASE_URL,
  STORE_ID,
  CONTACT_BACKGROUND_IMAGE_URL,
} from "../../utils"
import Button from "../common/Button"
import Input from "../common/Input"
import { toastMessage, TYPE_ERROR, TYPE_SUCCESS } from "../common/Toast"

import "./ContactSection.scss"

const ContactSection = () => {
  const { setLoader, isLoading } = useContext(AuthContext)
  const [state, setState] = React.useState({
    name: "",
    email: "",
    message: "",
  })
  const [message, setMessage] = React.useState("")

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoader(true)
      const response = await fetch(`${SUPERVISOR_BASE_URL}/api/contact-us/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ store_id: STORE_ID, ...state }),
      })
      if (response.status === 201) {
        setLoader(false)
        toastMessage("Message Sent Successfully!", TYPE_SUCCESS)
        setMessage("")
        setState({
          name: "",
          email: "",
          message: "",
        })
      }
    } catch (error) {
      setLoader(false)
      toastMessage(
        "Something went wrong. Please try after some time",
        TYPE_ERROR
      )
      console.log(error)
    }
  }

  return (
    <section className="contact-section">
      <div className="contact-section__content">
        <div className="contact-section__content__title">Contact Us</div>
        <div className="contact-section__content__description">
          Any issues? Looking for large order? Anything else? <br />
          Get in touch with us.
        </div>
        <form
          className="contact-section__content__form"
          onSubmit={handleSubmit}
        >
          <Input
            type="text"
            name="name"
            placeholder="Name"
            value={state.name}
            onChange={handleChange}
            required
            isBlock
            isLarge
            readOnly={isLoading}
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={state.email}
            onChange={handleChange}
            required
            isBlock
            isLarge
            readOnly={isLoading}
          />
          <Input
            type="textarea"
            name="message"
            placeholder="Message"
            value={state.message}
            onChange={handleChange}
            rows={4}
            required
            isBlock
            isLarge
            readOnly={isLoading}
          />
          <Button type="submit" isLarge>
            Submit
          </Button>
          {message && (
            <p className="contact-section__content__message">{message}</p>
          )}
        </form>
      </div>
      <div
        className="contact-section__image"
        style={{ backgroundImage: `url(${CONTACT_BACKGROUND_IMAGE_URL})` }}
      />
    </section>
  )
}

export default ContactSection
