import React from "react"

import ContactSection from "../components/sections/ContactSection"
import SEO from "../components/seo"

const ContactUsPage = () => {
  return (
    <>
      <SEO title="Contact us" path="/contact-us/" />
      <ContactSection />
    </>
  )
}

export default ContactUsPage
